import React, {Component} from "react";
import config from "../../config";
import {Elements, StripeProvider} from 'react-stripe-elements'; 
import CheckoutForm from "../CheckoutForm"

import "./PayButton.css";

class PayButton extends Component {
  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback

    this.state = {
      alreadyOrdered: false,
      orderedmsg: '',
      stripe: null
    };
    this.isValidOneTime = this.isValidOneTime.bind(this);
  }

  componentDidMount() {
    if (window.Stripe) { 
      this.setState({stripe: window.Stripe(config.stripe.apiKey)}); 
    } else { 
      document.querySelector('#stripe-js').addEventListener('load', () => { 
        // Create Stripe instance once Stripe.js loads 
       this.setState({stripe: window.Stripe(config.stripe.apiKey)}); 
        }); 
    }
  }
  isValidOneTime() {
    const {firstName, lastName, email} = this.state;

    if (!firstName || !lastName || !email ) {
      return false;
    }
    return true;
  }

  render() {
    const {alreadyOrdered, orderedmsg} = this.state;
    const { sku, amount} = this.props; 
    console.log(alreadyOrdered); 
    console.log(orderedmsg); 
    console.log("document.body.scrollHeight: ", document.body.scrollHeight);  
    return ( 
        <div className="product row"> 
          <div className="product-images col-xs-12 col-sm-12 col-lg-12"> 
            <div> 
              <div> 
                <StripeProvider stripe={this.state.stripe}> 
                  <Elements> 
                    <CheckoutForm sku={sku} amount={amount} /> 
                  </Elements> 
                </StripeProvider> 
              </div> 
            </div> 
          </div> 
        </div> 
    );
  }
}

export default PayButton;
