import React, {Component} from "react";
import config from "../../config";
import Product from "../Product";
import "./ProductList.css";
const firebase = require('firebase/app');
require ('firebase/analytics');

class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: []
    };
  }

  componentDidMount() {
    console.log('product list');
    // Your web app's Firebase configuration
    var firebaseConfig = {
      apiKey: "AIzaSyBbeppcnwdW5LnaC2mEq66_irGuZWvvE50",
      authDomain: "bitkoala-shopping.firebaseapp.com",
      databaseURL: "https://bitkoala-shopping.firebaseio.com",
      projectId: "bitkoala-shopping",
      storageBucket: "bitkoala-shopping.appspot.com",
      messagingSenderId: "657962089188",
      appId: "1:657962089188:web:aa947747a238a05b",
      measurementId: "G-C96NXCZCYL"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    this.fetchProducts();
  }

  async fetchProducts() { // Token returned from Stripe
    console.log('async1');
    const res = await fetch(config.stripe.productsUrl, { // Backend API url
      method: 'GET'
    });
    const response = await res.json();
    const products1 = response.data.Items;
    const res1 = await fetch(config.stripe.skusUrl, { // Backend API url
      method: 'GET'
    });
    const response2 = await res1.json();
    const skus1 = response2.data.Items;
    console.log(skus1);
    products1.map((product, index) => {
      console.log(product);
      return skus1.map((sku, index) => {
        if (product.product_id.S === sku.product_id.S) {
          console.log(sku);
          product['skus'] = [];
          product['skus']['data'] = [sku];
          console.log(product);
        }
        return sku;
      });
    });
    console.log(products1);
    this.setState({products: products1}, () => {
      console.log('set state');
    });

  }

  render() {
    console.log('render');
    const {products} = this.state;
    console.log(products);
    console.log({products});

    if (products === undefined) {
      return (
          <div id="products">
          </div>
      );
    } else {

    const productList = products.map((product, index) => {
      if ((product.active.BOOL )) {
        return (
            <Product id={product.product_id.S}
                     name={product.product_name.S}
                     caption={product.product_name.S}
                     description={product.description.S}
                     skus={product.skus.data}
                     images={[product.skus.data[0].image.S]} />
        );
      }
    });

    return (
        <div id="products">
          {productList}
        </div>
    );
    }
  }
}

export default ProductList;
