const config = {
  igoets: {
    websockets: "wss://mv1iqxqyab.execute-api.us-east-1.amazonaws.com/dev",
    chargelightningurl: 'https://api.igoets.com/charge_lightning',
    chargecrypto: 'https://api.igoets.com/charge_crypto'
  },
  stripe: {
    apiKey: 'pk_live_P93coEtCq0jlg8KCmUZyZl2u009VTkfoQa',
    checkoutUrl: 'https://api.igoets.com/charges',
    productsUrl: 'https://api.igoets.com/products',
    skusUrl: 'https://api.igoets.com/skus',
    currency: 'EUR',
  },
};

export default config;
