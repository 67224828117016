import React, {Component} from "react";
import "./PayButtonLightning.css";
import config from "../../config";
import { connect } from 'react-redux'
import {addTodo} from "../../actions";
import {MDBInput} from "mdbreact";
import Sockette from "sockette";

class PayButtonLightning extends Component {
    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.state = {
            shippingInfo: false,
            addedToBasketsubscription: false,
            addedToBasketonetime: false,
            alreadyOrdered: false,
            alreadyPaid: false,
            orderedmsg: '',
            qrcode: '',
            qrcode_id: '',
            qrcode_amount: "",
            qrcode_address: "",
            qrcode_currency: "",
            r_hash: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.isValidSubscription = this.isValidSubscription.bind(this);
        this.isValidOneTime = this.isValidOneTime.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        //alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
        console.log(this.state);
        const {firstName, lastName, email, crypto, street, streetNumber, city, zipcode, country, r_hash} = this.state;
        const {sku, amount, name, caption} = this.props;
        console.log(sku);
        console.log(amount);
        var body =  {
            "first_name": firstName, 
            "last_name": lastName, 
            "email": email, 
            "amount": amount / 100, //should be times 12 and 20 percent discount 
            "invoice_id": sku.id, 
            "street": street, 
            "streetNumber": streetNumber, 
            "city": city, 
            "zipcode": zipcode,
            "country": country
        };
        console.log(body);

        const res = await fetch(config.igoets.chargelightningurl, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(body)
        });
        console.log(res);
        const data = await res.json();
        console.log(data);
        if (res.status === 500) {
            this.setState({
                alreadyOrdered: true,
                orderedmsg: data.error,
                qrcode: "",
                qrcode_address: "",
                qrcode_amount: "",
                qrcode_currency: "",
                r_hash: ""
            });
        } else {
            var amount_in_btc = data.amount / 100000000; 
            console.log(amount_in_btc); 
            this.setState({ 
                alreadyOrdered: true, 
                orderedmsg: data.message, 
                qrcode: data.message1.payment_request, 
                qrcode_currency: "BTC", 
                qrcode_amount: amount_in_btc.toFixed(8),
                r_hash: data.message1.r_hash
            });
            var QRCode = require('qrcode');
            var canvas = document.getElementById('canvas');

            QRCode.toCanvas(canvas, data.message1.payment_request, function (error) {
                if (error) {
                    console.error(error);
                }
                console.log('success!');
            });
                 var ws = new Sockette(
          config.igoets.websockets,
                {
                    timeout: 5e3,
                    maxAttempts: 1,
                    onopen: e => console.log("connected:", e),
                    onmessage: e => {console.log("message", e); console.log(this.state.r_hash); console.log(e.data); if (this.state.r_hash === e.data) {this.setState({alreadyPaid: true, orderedmsg: 'payment received. check email for receipt.'});}},
                    onreconnect: e => console.log("Reconnecting...", e),
                    onmaximum: e => console.log("Stop Attempting!", e),
                    onclose: e => console.log("Closed!", e),
                    onerror: e => console.log("Error:", e)
                });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    isValidSubscription() {
        const {firstName, lastName, street, streetNumber, zipcode, city, country} = this.state;
        if (!firstName || !lastName || !street || !streetNumber || !zipcode  || !city || !country) {
            return false;
        }
        return true;
    }
    isValidOneTime() {
        const {firstName, lastName, email} = this.state;

        if (!firstName || !lastName || !email ) {
            return false;
        }
        return true;
    }

    render() {
        const {shippingInfo, addedToBasketsubscription, addedToBasketonetime,
            alreadyOrdered, alreadyPaid, orderedmsg, qrcode, qrcode_amount, qrcode_currency} = this.state;

        console.log(shippingInfo);
        console.log(addedToBasketsubscription);
        console.log(addedToBasketonetime);
        console.log(alreadyOrdered);
        console.log(alreadyPaid);
        console.log(orderedmsg);
        console.log(qrcode);
        console.log("document.body.scrollHeight: ", document.body.scrollHeight);

        return (
            <div className="product row">
                <div className="product-images col-xs-12 col-sm-12 col-lg-12">
                    <div>
                        <script src="bundle.js"></script>
                        {alreadyOrdered ?
                            <button className="btn btn-success btn-block
                            paybutton-lightning-success-message" > {orderedmsg} </button>
                            :

                            <div>

                                 <form onSubmit={this.handleSubmit} >  
                                      <p>Your Identity</p> 
                                     <MDBInput
                                          size='sm'
                                          label='FIRST NAME'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputFirstName" placeholder="FIRST NAME" 
                                           name="firstName" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='LAST NAME'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputLastName" placeholder="LAST NAME" 
                                           name="lastName" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='V2.BITWAGE.COM REGISTERED EMAIL'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputEmail" placeholder="V2.BITWAGE.COM REGISTERED EMAIL" 
                                           name="email" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        not v2.bitwage.com registered?&nbsp;  <a href="https://v2.bitwage.com/individual/sign-up">Signup</a> 

                                     <p>Your Mailing Address</p> 
                                     <MDBInput
                                          size='sm'
                                          label='STREET'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputStreet" placeholder="STREET" 
                                           name="street" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='NUMBER (0 if n/a)'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputStreetNumber" placeholder="NUMBER (0 if n/a)" 
                                           name="streetNumber" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='CITY'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputCity" placeholder="CITY" 
                                           name="city" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='ZIP'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputZip" placeholder="ZIP" 
                                           name="zipcode" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='COUNTRY (ISO-3)'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputCountry" placeholder="COUNTRY (ISO-3)" 
                                           name="country" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                            QR Code Shown After Form Submission 
                                     <button disabled={!this.isValidOneTime() || !this.isValidSubscription()} 
                                             className="btn btn-primary" type="submit">
                                              Purchase 
                                     </button> 
                                     <div className="product-selected-size">
                                         * powered by Peach 
                                     </div> 
                                 </form>
                            </div>
                        }
                        {alreadyPaid ? <div></div>
                            :
                            <div> <canvas id="canvas"></canvas>{qrcode_amount} {qrcode_currency}</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PayButtonLightning;
