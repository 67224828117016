import React, {Component} from "react";
import {
  MDBInput,
  MDBBtn,
} from 'mdbreact';
import "./PayButtonCrypto.css";
import config from "../../config";
import { connect } from 'react-redux'
import {addTodo} from "../../actions";
import Sockette from "sockette";

class PayButtonCrypto extends Component {
    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.state = {
            shippingInfo: false,
            addedToBasketsubscription: false,
            addedToBasketonetime: false,
            alreadyOrdered: false,
            alreadyPaid: false,
            orderedmsg: '',
            qrcode: '',
            qrcode_id: '',
            qrcode_amount: "",
            qrcode_address: "",
            qrcode_currency: "",
            crypto: "ETH",
            txn_id: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.isValidSubscription = this.isValidSubscription.bind(this);
        this.isValidOneTime = this.isValidOneTime.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        //alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
        console.log(this.state);
        const {firstName, lastName, email, crypto, street, streetNumber, city, zipcode, country, txn_id} = this.state;
        const {sku, amount, name, caption} = this.props;
        var curtime = Math.floor(Date.now() / 1000);
        var body =  {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "currency": crypto,
            "amount": amount / 100, //should be times 12 and 20 percent discount 
            "invoice_id": sku.sku_id.S, 
            "street": street, 
            "streetNumber": streetNumber, 
            "city": city, 
            "zipcode": zipcode,
            "country": country
        };
        console.log(body);

        const res = await fetch(config.igoets.chargecrypto, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(body)
        });
        console.log(res);
        const data = await res.json();
        console.log(data);
        if (res.status === 500) {
            this.setState({
                alreadyOrdered: true,
                alreadyPaid: false,
                orderedmsg: data.error,
                qrcode: "",
                qrcode_address: "",
                qrcode_amount: "",
                qrcode_currency: "",
                txn_id: ""
            });
        } else {
            this.setState({
                alreadyOrdered: true,
                alreadyPaid: false,
                orderedmsg: data.message,
                qrcode: data.message1.qrcode_url,
                qrcode_address: data.message1.address,
                qrcode_amount: data.message1.amount,
                qrcode_currency: crypto,
                txn_id: data.message1.txn_id
            });
             var ws = new Sockette(config.igoets.websockets,
                {
                    timeout: 5e3,
                    maxAttempts: 1,
                    onopen: e => console.log("connected:", e),
                    onmessage: e => {console.log("message", e); {console.log("message", e); console.log(this.state.txn_id); console.log(e.data); if (this.state.txn_id === e.data) {this.setState({alreadyPaid: true, orderedmsg: 'payment received. check email for receipt.'});}}},
                    onreconnect: e => console.log("Reconnecting...", e),
                    onmaximum: e => console.log("Stop Attempting!", e),
                    onclose: e => console.log("Closed!", e),
                    onerror: e => console.log("Error:", e)
                });
        }

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    isValidSubscription() {
        const {firstName, lastName, street, streetNumber, zipcode, city, country} = this.state;

        if (!firstName || !lastName || !street || !streetNumber || !zipcode || !city || !country) {
            return false;
        }
        return true;
    }
    isValidOneTime() {
        const {firstName, lastName, email, crypto} = this.state;

        if (!firstName || !lastName || !email || !crypto) {
            return false;
        }
        return true;
    }

    render() {
        const {shippingInfo, addedToBasketsubscription, addedToBasketonetime,
            alreadyOrdered, alreadyPaid, orderedmsg, qrcode, qrcode_address, qrcode_amount, qrcode_currency} = this.state;

        console.log(shippingInfo);
        console.log(addedToBasketsubscription);
        console.log(addedToBasketonetime);
        console.log(alreadyOrdered);
        console.log(alreadyPaid);
        console.log(orderedmsg);
        console.log(qrcode);
        console.log("document.body.scrollHeight: ", document.body.scrollHeight);

        return (
            <div className="product row">
                <div className="product-images col-xs-12 col-sm-12 col-lg-12">
                    <div>
                        {alreadyOrdered ?
                            <button className="btn btn-success btn-block paybutton-crypto-success-message" > {orderedmsg} </button>
                            :

                            <div>
                                <form onSubmit={this.handleSubmit} className='grey-text'> 
                                    <p>Your Identity</p> 
                                        <MDBInput
                                          size='sm'
                                          label='FIRST NAME'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputFirstName" placeholder="FIRST NAME" 
                                           name="firstName" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='LAST NAME'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputLastName" placeholder="LAST NAME" 
                                           name="lastName" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='EMAIL'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputEmail" placeholder="EMAIL" 
                                           name="email" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                     <p>Your Mailing Address</p> 
                                    <MDBInput
                                          size='sm'
                                          label='STREET'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputStreet" placeholder="STREET" 
                                           name="street" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='NUMBER (0 if n/a)'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputStreetNumber" placeholder="NUMBER (0 if n/a)" 
                                           name="streetNumber" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='CITY'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputCity" placeholder="CITY" 
                                           name="city" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='ZIP'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputZip" placeholder="ZIP" 
                                           name="zipcode" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                        <MDBInput
                                          size='sm'
                                          label='COUNTRY (ISO-3)'
                                          group
                                          type='text'
                                          validate
                                          error='wrong'
                                          success='right'
                                          id="inputCountry" placeholder="COUNTRY (ISO-3)" 
                                           name="country" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}
                                        />
                                            QR Code Shown After Form Submission 
                                        <input value="ETH" type="hidden" id="inputCrypto" name="crypto"/> 
                                          <MDBBtn color='primary' disabled={!this.isValidOneTime() || !this.isValidSubscription()} 
                                                className="btn btn-primary" 
                                                type="submit">
                                              Purchase
                                          </MDBBtn>
                                        <div className="product-selected-size"> 
                                            * powered by CoinPayments 
                                        </div> 
                                </form>
                            </div>
                        }
                         {alreadyPaid ?  <div></div>
                            :
                            <div><img src={qrcode}/> {qrcode_address} {qrcode_amount} {qrcode_currency} </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PayButtonCrypto;
