import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse,   MDBNavItem, MDBFooter, MDBNavLink } from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import Footer from '../Footer'
import Header from "../Header";
import ProductList from "../ProductList";
import AddTodo from '../../containers/AddTodo'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

class App extends Component {

    state={
        collapseID: ""
    }

    toggleCollapse = collapseID => () =>
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));

    closeCollapse = collapseID => () =>
        this.state.collapseID === collapseID && this.setState({ collapseID: "" });

    render() {

        const overlay = (
            <div
                id="sidenav-overlay"
                style={{ backgroundColor: "transparent" }}
                onClick={this.toggleCollapse("mainNavbarCollapse")}
            />
        );

        const { collapseID } = this.state;

    return (
        <Router>

      <div className="App">
          <MDBNavbar color="indigo" dark expand="md" fixed="top" scrolling> 
              <MDBNavbarBrand href="/"> 
                  <img height="50" src="https://d1p2s4ms6zvr32.cloudfront.net/BitKoala_Logo_Blue_sized.jpg" /> 
              </MDBNavbarBrand> 
          </MDBNavbar>
        <div className="container">
          <ProductList/>
        </div>
          <MDBFooter color="indigo"> 
              <p className="footer-copyright mb-0 py-3 text-center"> 
                  &copy; 2021 <a href="https://www.bitkoala.com"> Bitkoala Services Tecnologia, Ltda. </a> 
              </p> 
              <p className="footer-copyright mb-0 py-3 text-center"> 
                  Made in <a href="https://www.inweizhe.com/"> Republic of the Philippines</a> 
              </p> 
          </MDBFooter>
      </div>
        </Router>
    );
  }
}

export default App;
