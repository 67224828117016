import React, {Component} from 'react'; 
import {CardElement, injectStripe} from 'react-stripe-elements'; 
import config from "../../config";  
class CheckoutForm extends Component { 
    constructor(props) { 
        super(props); 
        this.submit = this.submit.bind(this); 
        this.handleInputChange = this.handleInputChange.bind(this); 
        this.isValidSubscription = this.isValidSubscription.bind(this); 
        this.handleSubmit = this.handleSubmit.bind(this); 
        this.state = { 
            alreadyOrdered: false, 
            orderedmsg: '' 
        }; 
    } 
    async submit(ev) { 
        // User clicked submit 
    }  

    async handleSubmit(event) { 
        //alert('A name was submitted: ' + this.state.value); 
        event.preventDefault(); 
        console.log(this.state); 
        const {firstName, lastName, email, street, streetNumber, zipcode, city} = this.state; 
        const {sku, amount} = this.props;  
        console.log(this.state); 
        console.log(this.props); 
        //var curtime = Math.floor(Date.now() / 1000); 
        var body =  { 
            "first_name ": firstName, 
            "last_name": lastName,
            "email": email, 
            "currency": "eur", 
            "amount": amount,//should be times 12 and 20 percent discount 
            "invoice_id": sku.id 
        }; 
        console.log(body);  
        let currentComponent = this; 
        // You can also use createToken to create tokens. 
        // See our tokens documentation for more: 
        // https://stripe.com/docs/stripe-js/reference#stripe-create-token 
        this.props.stripe.createToken({ 
            billing_details: { 
                name: firstName + ' ' + lastName, 
                address_line1: street + " " + streetNumber, 
                address_city: city, 
                address_state: '', 
                address_zip: zipcode, 
                address_country: '' 
            } 
        }).then(function(result) { 
            console.log(result); 
            if (result.error) { 
                console.log(result.error); 
                currentComponent.setState({ 
                    alreadyOrdered: true, 
                    orderedmsg: result.error.message 
                }); 
                return true; 
            } else { 
                const token_id = result.token.id; 
                fetch(config.stripe.checkoutUrl, {
                    // Backend API url 
                    method: 'POST', 
                    body: JSON.stringify({ 
                        token: { 
                            id: token_id, 
                            email: email 
                        }, 
                        order: { 
                            currency: config.stripe.currency, 
                            items: [ 
                                { 
                                    type: 'sku', 
                                    parent: sku.id 
                                } 
                                ], 
                            shipping: { 
                                name: firstName + " " + lastName, 
                                address: { 
                                    line1: street + " " + streetNumber, 
                                    city: city, 
                                    postal_code: zipcode 
                                }
                             
                            } 
                            }, 
                        pid: { 
                            "first_name": firstName, 
                            "last_name": lastName,
                            "street": street,
                            "streetNumber": streetNumber, 
                            "zipcode": zipcode,
                             "city": city 
                        } 
                    }),
                }).then(function(result) { 
                    console.log(result); 
                    return result.json(); 
                }).then(function(result){ 
                    console.log(result); 
                    if (result.code === 200) { 
                        console.log(result.success); 
                        var success_msg = 'successfully charged card'; 
                        if (result.success) { 
                            success_msg = result.success.charge_id 
                        } 
                        currentComponent.setState({ 
                            alreadyOrdered: true, 
                            orderedmsg: success_msg 
                        }); 
                        return true; 
                    } else { 
                        console.log(result.error); 
                        var error_msg = 'unsuccessfully charged card'; 
                        if (result.error) { 
                            error_msg = result.error.error_msg 
                        } 
                        currentComponent.setState({ 
                            alreadyOrdered: true, 
                            orderedmsg: error_msg 
                        }); 
                        return true; 
                    } 
                }); 
            } 
        }); 
    } 

    handleInputChange(event) { 
        const target = event.target; 
        const value = target.type === 'checkbox' ? target.checked : target.value; 
        const name = target.name;  
        this.setState({ 
            [name]: value 
        });  
    } 

    isValidSubscription() { 
        const {firstName, lastName, street, streetNumber, zipcode, city} = this.state;  
        if (!firstName || !lastName || !street || !streetNumber || !zipcode  || !city) {
            return false; 
        } 
        return true; 
    } 

    render() { 
        const {alreadyOrdered, orderedmsg} = this.state; 
        console.log(alreadyOrdered); 
        console.log(orderedmsg); 
        return ( 
            <div> 
                { 
                    alreadyOrdered ? 
                        <button className="btn btn-success btn-block paybutton-success-message" onClick={() => this.setState({alreadyOrdered: false})}> {orderedmsg} </button> 
                        : 
                        <form onSubmit={this.handleSubmit}> 
                            <p>Your Billing Information</p> 
                            <div className="form-row"> 
                                <div className="form-group col-md-6"> 
                                    <input type="text" className="form-control" 
                                           id="inputFirstName" placeholder="FIRST NAME" 
                                           name="firstName" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}/> 
                                </div> 
                                <div className="form-group col-md-6"> 
                                    <input type="text" className="form-control" 
                                           id="inputLastName" placeholder="LAST NAME" 
                                           name="lastName" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}/> 
                                </div> 
                            </div> 
                            <div className="form-row"> 
                                <div className="form-group col-md-8"> 
                                    <input type="text" className="form-control" 
                                           id="inputEmail" 
                                           placeholder="V2.BITWAGE.COM REGISTERED EMAIL" name="email" 
                                           onChange={this.handleInputChange} 
                                           onBlur={this.handleInputChange}/> 
                                </div> 
                                <div className="form-group col-md-4"> 
                                    not v2.bitwage.com registered?&nbsp; 
                                    <a href="https://v2.bitwage.com/individual/sign-up">Signup</a>
                                </div> 
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-8"> 
                                <input type="text" className="form-control" 
                                       id="inputStreet" 
                                       placeholder="STREET" name="street" 
                                       onChange={this.handleInputChange} 
                                       onBlur={this.handleInputChange}/> 
                            </div> 
                            <div className="form-group col-md-4"> 
                                <input type="text" className="form-control" 
                                       id="inputStreetNumber"
                                       placeholder="NUMBER" name="streetNumber"
                                       onChange={this.handleInputChange} 
                                       onBlur={this.handleInputChange}/> 
                            </div> 
                            </div> 
                            <div className="form-row"> 
                                <div className="form-group col-md-8"> 
                                    <input type="text" className="form-control"
                                       id="inputCity" 
                                       placeholder="CITY" name="city" 
                                       onChange={this.handleInputChange}
                                       onBlur={this.handleInputChange}/> 
                                </div>
                             <div className="form-group col-md-4"> 
                                 <input type="text" className="form-control"
                                        id="inputZip" 
                                        placeholder="ZIP" name="zipcode" 
                                        onChange={this.handleInputChange} 
                                        onBlur={this.handleInputChange}/> 
                            </div> 
                            </div> 
                            <CardElement /> 
                            <div> 
                                Card Charged After Form Submission 
                            </div> 
                            <button disabled={!this.isValidSubscription()} 
                                    className="btn btn-primary " 
                                    type="submit"> 
                                Purchase 
                            </button> 
                            <div className="product-selected-size"> 
                                * powered by Stripe 
                            </div> 
                        </form> 
                } 
            </div> 
        ); 
    } 
}  

export default injectStripe(CheckoutForm);
