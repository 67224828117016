import React, {Component} from "react";
import "./QrCode.css";
import { Provider } from 'react-redux'

class QrCode extends Component {

  constructor(props) {
    super(props);

    this.state = {
      qrcode: '',
      qrcode_id: ''
    };
  }

  render() {
    const {qrcode_id, qrcode} = this.state;

    return (
        <div key={qrcode_id} className="product row">
          <div className="qrcode-images col-xs-12 col-sm-6 col-lg-6">
            <div className="qrcode-image-wrapper row">
              <div className="col-xs-12 col-lg-10">
                <img className="product-image img-fluid"
                     src={qrcode}/>
              </div>
            </div>
          </div>
        </div>
    );
  }
};

export default QrCode;