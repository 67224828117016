import React, {Component} from "react";
import { MDBBtn, MDBBtnGroup, MDBIcon, MDBContainer, MDBCol, MDBRow, MDBDropdown,
  MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { MDBModal, MDBModalBody, ModalHeader, MDBModalFooter, MDBInput, MDBTabContent, MDBTabPane, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import "./Product.css";
import PayButton from "../PayButton";
import PayButtonCrypto from "../PayButtonCrypto";
import PayButtonLightning from "../PayButtonLightning";
import QrCode from "../QrCode";
import VisibleTodoList from "../../containers/VisibleTodoList";


class Product extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
      currentSku: props.skus[0],
      qrcode: '',
      qrcode_id: '',
      iscardbtn: true,
      iscryptobtn: true,
      isltnbtn: true,
      modal1: false, 
      modal2: false, 
      modal3: false, 
      activeItem: "1"
    };
  }

  toggle = nr => () => { 
    let modalNumber = "modal" + nr;
    this.setState({ 
      [modalNumber]: !this.state[modalNumber]
    });
  };

  render() {
    const {id, name, caption, images, description, skus} = this.props;
    const {currentImage, currentSku, iscardbtn, iscryptobtn, isltnbtn} = this.state;

    const thumbnails = images.map((image, index) => {
      console.log(image)
      return <img key={index}
                  className={"product-thumbnail " + (index === currentImage
                      ? 'selected' : '')}
                  onClick={() => this.setState({currentImage: index})}
                  src={image} width="75"/>
    });

    const skuList = skus.map((sku, index) => {

      const {attributes} = sku;

      return (
          <div key={sku.sku_id.S}
               className={"col-xs-2 product-sku " + (currentSku.sku_id.S === sku.sku_id.S
                   ? 'selected' : '')}
               onClick={() => this.setState({currentSku: sku})}>
          </div>
      );
    });
    function pad_with_zeroes(number, length) {

        var my_string = '' + number;
        while (my_string.length < length) {
            my_string = '0' + my_string;
        }

        return my_string;

    }
    const price = currentSku.price.S + "";
    const euros = price.substring(0, price.length - 2);
    const cents = price.slice(-2);

    return (
        <div key={id} className="product row">
          <div className="product-images col-xs-12 col-sm-6 col-lg-6">
            <div className="product-image-wrapper row">
              <div className="col-xs-12 col-lg-2 d-none d-lg-block d-xl-block">
                <div className="product-thumbnails">{thumbnails}</div>
              </div>
              <div className="col-xs-12 col-lg-10">
                <img className="product-image img-fluid"
                     src={images[currentImage]}/>
              </div>
            </div>
          </div>
          <div className="product-details col-xs-12 col-sm-6 col-lg-6">
            <h2 className="product-name">{name}</h2>
            <h1 className="product-caption">{caption}</h1>
            <div
                className="product-price">
              <div>{euros}.{pad_with_zeroes(cents, 2)} {currentSku.currency.S.toUpperCase()}</div>
              <div className="product-taxes">incl taxes</div>
            </div>
            <p className="product-description">{description}</p>
            <hr/>
            <div className="product-sizes-list row">
              {skuList}
            </div>
            <hr/> 
                <h5>Buy It Now</h5> 
            <MDBRow> 
              <MDBCol md='12' className="mb-4"> 
                <MDBBtnGroup size="md"> 
                  <MDBBtn size="sm" onClick={this.toggle(2)}>ETH</MDBBtn> 
                </MDBBtnGroup> 
              </MDBCol>  
              <hr/>  
              <MDBModal 
                  className="form-elegant"
                  isOpen={this.state.modal2} 
                  toggle={this.toggle(2)} 
              > 

                <div className='modal-header primary-color white-text'>
                  <h4 className='title'>
                     ETH
                  </h4>
                </div>
                <MDBModalBody className="mx-3"> 
                  {iscryptobtn ? (<PayButtonCrypto disabled={(currentSku.inventory_type.S == 'finite' && currentSku.inventory_quantity.N < 1)} 
                               amount={price} sku={currentSku} name={name} caption={caption}/>) : <div></div> 
                  } 
                </MDBModalBody> 
                <MDBModalFooter className="justify-content-center mx-4"> 
                  <MDBBtn outline color="info" onClick={this.toggle(2)}> 
                    CLOSE 
                  </MDBBtn> 
                </MDBModalFooter> 
              </MDBModal> 

            </MDBRow>
            <hr/>
            {currentSku.attributes ?
                null : null}
          </div>
        </div>
    );
  }
}
;

export default Product;